import React from 'react'
import './about.css'
import ABOUT from '../../assets/About.jpeg'

const About = () => {
  return (
    <section id='about'>
      <h2>About Spot Occupational Therapy</h2>

      <div className='container about__container'>
        <div className='about__thespot'>
          <div className='about__thespot-image'>
            <img src={ABOUT} alt="child using paper" />
          </div>
        </div>


        <div className='about__content'>
          <div className='about__cards'>
            <article className='about__card'>
              <h2>Our Mission:</h2>
              <h5>SPOT Occupational Therapy exists to support and educate children and 
                their families, by fostering independence in their meaningful activities.</h5>
            </article>
          </div>
        </div>

        <div className='about__content'>
          <div className='about__cards'>
            <article className='about__card'>
              <h2>Our Vision:</h2>
              <h5>We achieve this through our diverse service offerings designed 
                to empower and equip families and clients in their journey toward greater 
                independence.</h5>
            </article>
          </div>
        </div>

        <div className='about__content'>
          <div className='about__cards'>
            <article className='about__card'>
              <h2>Philosophy:</h2>
              <p>We believe that success starts with a strong therapeutic rapport 
                between the child, the family, and the clinician.</p>  
                
                <p>We see each child as a unique individual, not a diagnosis. 
                We respect their right to privacy and protect the dignity of all our 
                clients.</p> 
                
                <p>We believe in supporting and fostering independence in the 
                areas of life that YOU deem meaningful and purposeful!</p> 

                <p>We are creative healers. We help children reach their full 
                potential one step at a time, using methods as unique as each 
                child is.</p> 

                <p>The goal is not perfection or comparison. We are never done 
                learning and growing.</p>
            </article>
          </div>
        </div>

      </div>
    </section>
  )
}

export default About