import React from 'react'
import "./testimonials.css"
import "swiper/css"
import "swiper/css/pagination"

import { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import TestimonialOne from '../../assets/TestimonialOne.jpeg'
import TestimonialTwo from '../../assets/TestimonialTwo.jpeg'
import TestimonialThree from '../../assets/TestimonialThree.jpeg'
import TestimonialFour from '../../assets/TestimonialFour.jpeg'


const reviews = [
    {
        image: TestimonialOne,
    },
    {
        image: TestimonialTwo,
    },
    {
        image: TestimonialThree,
    },
    {
        image: TestimonialFour,
    }
]


const Testimonials = () => {
  return (
    <section id='testimonials'>
        <h2>Testimonials</h2>

        <Swiper className='container testimonials__container'
        modules={[Pagination]}
        spaceBetween={40}
        slidesPerView={1}
        pagination={{clickable: true }}>
            {
                reviews.map(({image}, index) => {
                    return (
                        <SwiperSlide key={index} className='testimonial'>
                            <div className='client__review'>
                                <img src={image} alt="client testimonial"/>
                            </div>
                        </SwiperSlide>
                    )
                })
            }
        </Swiper>
    </section>
  )
}

export default Testimonials