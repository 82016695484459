import React from 'react'
import './header.css'
import THESPOT from '../../assets/TheSpot.jpg'
import CTA from './CTA'
import HeaderSocials from './HeaderSocials'

const Header = () => {
  return (
    <header>
      <div className="container header__container">
        
        <h5>Hello</h5>
        <h1>Welcome To TheSpot</h1>
        <CTA />

        <div className='logo'>
          <img className='center-fit' src={THESPOT} alt="company logo" />
        </div>

        <HeaderSocials />
        <a href="#contact" className='scroll__down'>Scroll Down</a>

      </div>
    </header>
  )
}

export default Header