import React from 'react'
import './staff.css'
import PROFILEPIC from '../../assets/ProfilePic.jpeg'

const Staff = () => {
  return (
    <section id='staff'>
      <h2>Meet Our Staff</h2>

      <div className='staff__container'>
          <div className='staff__cards'>
            <article className='staff__card'>
              <h2>Mackenzy Ryan, OTR/L, M.S.O.T.</h2>
              <div className='staff__mack'>
                <div className='staff__mack-image'>
                  <img src={PROFILEPIC} alt='Picture of Staff' />
                </div>
              </div>
              <p>"Hi! My name is Mackenzy and I am a certified occupational therapist 
                through NBCOT, and licensed in the states of Connecticut and Massachusetts. 
                I earned a Master's of Science Degree in Occupational Therapy from Springfield 
                College, and a Bachelor's of Education from Central Connecticut State University. 
                Over the past several years I have gained experience working with children 
                ages 3-18 years old in public schools across CT and MA. I have also worked 
                with children and adolescents with a variety of mental health conditions in 
                a private trauma-informed school, as well as working with adults who 
                sustained a brain injury in a subacute rehabilitation hospital. I started 
                The SPOT because I want to help parents foster greater independence in 
                their child's lives, as well as equip and empower parents to be their 
                child's best advocate!" </p>  
            </article>
          </div>
        </div>

    </section>
  )
}

export default Staff