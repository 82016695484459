import React from 'react'
import './footer.css'
import {BsInstagram} from 'react-icons/bs'
import {BsFacebook} from 'react-icons/bs'


const Footer = () => {
  return (
    <footer>
      <a href='#' className='footer__logo'>TheSpot</a>

      <ul className='permalinks'>
        <li><a href='#'>Home</a></li>
        <li><a href='#about'>About</a></li>
        <li><a href='#services'>Services</a></li>
        <li><a href='#staff'>Staff</a></li>
        <li><a href='#contact'>Contact</a></li>
      </ul>

      <div className='footer__socials'>
        <a href="https://www.facebook.com/thespotllc" target="_blank" rel="noreferrer"><BsFacebook /></a>
        <a href="https://www.instagram.com/thespotllc/" target="_blank" rel="noreferrer"><BsInstagram /></a>
      </div>

      <div className="footer__copyright">
        <small>&copy; Spot Occupational Therapy LLC. All rights reserved</small>
      </div>


    </footer>
  )
}

export default Footer