import React, {useRef} from 'react'
import emailjs from 'emailjs-com'
import './contact.css'
import {BsInstagram} from 'react-icons/bs'
import {SiMinutemailer} from 'react-icons/si'
import {BsFacebook} from 'react-icons/bs'


const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_5yhj2k9', 'template_ui055uc', form.current, '29xhMT_UQQ5TlQk0n')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
    e.target.reset()
  };


  return (
    <section id='contact'>
      <h2>Contact Me</h2>

      <div className='container contact__container'>
        <div className='contact__options'>
          <article className="contact__option">
            <SiMinutemailer className='contact__option-icon'/>
            <h4>Email</h4>
            <h5></h5>
            <a href="mailto:Contact@thespotllc.org" target="_blank" rel="noreferrer">Send an email</a>
          </article>
          <article className="contact__option">
            <BsFacebook className='contact__option-icon'/>
            <h4>Facebook</h4>
            <h5>The Spot Occupational Therapy</h5>
            <a href="https://www.facebook.com/thespotllc" target="_blank" rel="noreferrer">Come visit us</a>
          </article>
          <article className="contact__option">
            <BsInstagram className='contact__option-icon'/>
            <h4>Instagram</h4>
            <h5>thespotllc</h5>
            <a href="https://www.instagram.com/thespotllc/" target="_blank" rel="noreferrer">Take a look!</a>
          </article>
        </div>

        <form ref={form} onSubmit={sendEmail}>
          <input type='text' name='name' placeholder='Your Full Name' required />
          <input type='email' name='email' placeholder='Your Email' required />
          <textarea name='message' rows='7' placeholder='Your Message' required ></textarea>
          <button type='submit' className='btn btn-primary'>Send Message</button>
        </form>
      </div>
    </section>
  )
}

export default Contact