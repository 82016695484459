import React from 'react'
import './services.css'
import SERVICES from '../../assets/Services.jpeg'

const Services = () => {
  return (
    <section id='services'>
      <h2>Our Services</h2>
      
      <div className='services__block'>
        <div className='services__block-image'>
          <img src={SERVICES} alt='toy blocks' />
        </div>
      </div>

      <div className='container services__container'>
        <article className='service'>
          <div className='service__head'>
            <h2>Adaptive Fitness</h2>
          </div>
          <p>For our children and adolescents with neurodiversity, exercise can be 
            an AMAZING source for rehabilitation and improved functional performance! 
            This Adaptive Fitness class is for both child and parent. We specialized 
            in functional movement, strength and endurance, and improved coordination 
            and motor planning!</p>
        </article>
      
        <article className='service'>
          <div className='service__head'>
            <h2>OT Consultation</h2>
          </div>
          <p>OT consultation is offered in-person or virtually for pediatrics and 
            adults. Consultation consists of discussion, instruction, and/or education 
            of accommodations, strategies, and recommendations provided. This also 
            includes ordering and education of adaptive equipment.</p>
        </article>

        <article className='service'>
          <div className='service__head'>
            <h2>IEP Education & Collaboration</h2>
          </div>
          <p>IEP education is offered to parents and guardians who want to gain a 
            better understanding of the IEP process. IEP collaboration is offered to 
            discuss their child’s IEP including concerns, goals, and evaluation review. 
            IEP education and collaboration can occur before and/or after the IEP 
            meeting has occurred.</p>
        </article>

        <article className='service'>
          <div className='service__head'>
            <h2>Pediatric OT Treatment</h2>
          </div>
          <p>Direct occupational therapy treatment occurs within the client’s 
            home. This also includes parent consultation as well as written 
            documentation upon request. Direct Treatment may occur on a weekly, 
            bi-weekly, or monthly basis. </p>
        </article>
      </div>

    </section>
  )
}

export default Services